<template>
  <b-modal v-model="isShown" hide-header hide-footer>
    <b-form @submit.prevent="onActionConfirm">
      <slot name="body"></slot>
      <div class="mt-2 float-right">
        <b-button variant="light" size="sm" @click="onActionCancel">
          <span class="label">{{ cancelButton }}</span>
        </b-button>
        <processing-button
          :variant="actionButtonVariant"
          size="sm"
          :label="actionButton"
          class="ml-2"
          :processing="processing"
          :error="error"
        ></processing-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import ProcessingButton from '@/components/Common/ProcessingButton.vue'
export default {
  components: {
    ProcessingButton
  },
  props: {
    cancelButton: {
      type: String,
      default: 'Cancel'
    },
    actionButton: {
      type: String,
      default: 'Confirm'
    },
    actionButtonVariant: {
      type: String,
      default: 'primary'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: null
    }
  },
  methods: {
    onActionCancel() {
      this.$emit('actionCanceled')
      this.isShown = false
    },
    onActionConfirm() {
      this.$emit('actionConfirmed')
    }
  },
  computed: {
    isShown: {
      get() {
        return this.showModal
      },
      set(newValue) {
        this.$emit('modalTrigger', newValue)
      }
    }
  }
}
</script>
