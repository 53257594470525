var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { "hide-header": "", "hide-footer": "" },
      model: {
        value: _vm.isShown,
        callback: function ($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown",
      },
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onActionConfirm.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("body"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-2 float-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "light", size: "sm" },
                  on: { click: _vm.onActionCancel },
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.cancelButton)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("processing-button", {
                staticClass: "ml-2",
                attrs: {
                  variant: _vm.actionButtonVariant,
                  size: "sm",
                  label: _vm.actionButton,
                  processing: _vm.processing,
                  error: _vm.error,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }